import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Carousel } from 'react-responsive-carousel';
import * as carousel from '../../global_css/carousel.css';
import s from './Product.css';

const Product = ({
  price,
  quantity,
  title,
  images,
  currency,
  thumb,
  bordered,
  onRemoveFromCartClicked,
}) => (
  <div className={s.product}>
    {!thumb && (
      <Carousel
        className={s.sliderItems}
        emulateTouch
        thumbWidth={40}
        showIndicators={false}
        showStatus={false}
        autoPlay
      >
        {images.map(img => (
          <picture key={img.key} className={s.imgContainer}>
            <source srcSet={img.srcset} type="image/webp" />
            <source srcSet={img.src} type="image/jpeg" />
            <img className={s.miniImg} src={img.src} alt="cart-img" />
          </picture>
        ))}
      </Carousel>
    )}
    <div className={cx(s.productInfo, bordered ? s.borderedTop : '')}>
      <p>{title}</p>
      <div className={cx(!thumb ? s.productCt : '', thumb ? s.bordered : '')}>
        {thumb && (
          <div className={s.thumbContainer}>
            <img
              key={thumb.key}
              className={s.thumb}
              alt="thumb-img"
              src={thumb.src}
            />
          </div>
        )}
        <span className={s.price}>
          {price}
          <small className={s.currency}>{currency}</small>
        </span>{' '}
        {quantity && (
          <span className={s.qty}>
            <FormattedMessage id="product.qty" defaultMessage="Qty." />{' '}
            {quantity}
          </span>
        )}
        {onRemoveFromCartClicked && (
          <button
            className={s.btnRemove}
            onClick={onRemoveFromCartClicked}
            disabled={quantity > 0 ? '' : 'disabled'}
          >
            {'X'}
          </button>
        )}
      </div>
    </div>
  </div>
);

Product.propTypes = {
  price: PropTypes.number,
  quantity: PropTypes.number,
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  thumb: PropTypes.objectOf(PropTypes.string),
  currency: PropTypes.string.isRequired,
  onRemoveFromCartClicked: PropTypes.func,
  bordered: PropTypes.bool,
};

Product.defaultProps = {
  bordered: false,
  price: 0,
  quantity: 1,
  title: '',
  images: [],
  thumb: null,
  onRemoveFromCartClicked: null,
};

Product.state = { index: 0 };

Product.dotClicked = e => {
  this.setState({ index: parseInt(e.target.dataset.index, 10) });
};

const mapStateToProps = state => ({
  currency: state.currency.currentCurrency,
});

export default connect(mapStateToProps)(withStyles(s, carousel)(Product));
