import { API } from '../constants';

/* eslint no-param-reassign: "off" */
export const addOrReplace = (newStyles, oldStyles) => {
  Object.keys(oldStyles).map(objectKey => {
    const value = newStyles[objectKey];
    if (value && value !== oldStyles[objectKey]) {
      oldStyles[objectKey] = newStyles[objectKey];
    }
    return oldStyles;
  });
  return Object.assign(newStyles, oldStyles);
};

export const saveState = (key, data) => {
  try {
    const serialized = JSON.stringify(data);
    localStorage.setItem(key, serialized);
  } catch (err) {
    // Ignore errors.
  }
};

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const getCheckout = (params, fetch) =>
  fetch(API.SIGNATURE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  })
    .then(r => r.json())
    .then(data =>
      fetch(API.CHECKOUT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then(r => r.json())
        .then(url => url),
    );

export const getToken = (params, fetch) =>
  fetch(API.SIGNATURE, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  })
    .then(r => r.json())
    .then(data =>
      fetch(API.TOKEN, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then(r => r.json())
        .then(url => url.response.token),
    );

export const canUseWebP = () => {
  const elem =
    typeof document === 'object' ? document.createElement('canvas') : {};

  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

export const makeId = () => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 6; i += 1)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);
