import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Ink from 'react-ink';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Product from '../Product/Product';
import { removeFromCart } from '../../actions/cart';
import s from './Cart.css';
import history from '../../history';
import cart from './cart.svg';
import { toLocalized } from '../../helpers/locale';
import { getCheckout } from '../../helpers';

class Cart extends React.Component {
  async handleCheckout() {
    const desc = this.props.products.map(p => p.title).join(', ');
    const url = await getCheckout(
      {
        total: this.props.total,
        currency: this.props.currency,
        desc,
      },
      this.props.fetch,
    );
    if (process.env.BROWSER) {
      await localStorage.setItem('frameUrl', url.response.checkout_url);
    }
  }

  async handleToCheckout() {
    await this.handleCheckout();
    history.push(toLocalized('/checkout', this.props.currentLocale));
  }

  render() {
    const {
      products,
      total,
      // onCheckoutClicked,
      currency,
      removeFromCart,
    } = this.props;
    const hasProducts = products.length > 0;
    const nodes = hasProducts ? (
      products.map(product => (
        <Product
          title={product.title}
          price={product.price}
          quantity={product.quantity}
          thumb={product.images[0]}
          key={product.id}
          onRemoveFromCartClicked={() => removeFromCart(product.id)}
        />
      ))
    ) : (
      <em>
        <FormattedMessage
          id="cart.add_to_cart"
          defaultMessage="Please add some products to cart."
        />
      </em>
    );

    return (
      <div className={s.root}>
        <div className={s.text}>
          <div className={s.message}>
            <FormattedMessage id="cart.title" defaultMessage="Your Cart" />
          </div>
          <img className={s.image} alt="Cart" src={cart} />
        </div>
        <div>{nodes}</div>
        <p className={s.total}>
          <FormattedMessage id="cart.total" defaultMessage="Total: " /> {total}{' '}
          {currency}
        </p>
        <button
          className={s.cartBtn}
          onClick={() => this.handleToCheckout()}
          disabled={hasProducts ? '' : 'disabled'}
        >
          <Ink />
          <FormattedMessage
            id="cart.checkoutPage"
            defaultMessage="Go to Checkout Page"
          />
        </button>
      </div>
    );
  }
}

Cart.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  total: PropTypes.string.isRequired,
  // onCheckoutClicked: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currency: state.currency.currentCurrency,
  currentLocale: state.intl.locale,
});

export default connect(mapStateToProps, { removeFromCart })(
  withStyles(s)(Cart),
);
