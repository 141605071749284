/* eslint-env es6 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Ink from 'react-ink';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { checkout } from '../../actions/cart';
import { getTotal, getCartProducts, getCartTotal } from '../../reducers/';
import Cart from '../../components/Cart/Cart';
import s from './CartContainer.css';
import cartSvg from '../../components/Cart/cart.svg';

class CartContainer extends React.Component {
  state = {
    isOpen: false,
  };
  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeFloatCart = () => {
    this.setState({ isOpen: false });
  };
  render() {
    const { products, total, checkout, totalItems, fetch } = this.props;
    return (
      <div className={cx(s.root, this.state.isOpen ? s.active : '')}>
        {this.state.isOpen && (
          <button onClick={() => this.closeFloatCart()} className={s.close}>
            X
          </button>
        )}
        {!this.state.isOpen && (
          <button
            id="cartButton"
            onClick={() => this.openFloatCart()}
            className={s.bag}
          >
            <img width="25px" alt="Cart" src={cartSvg} />
            <span className={s.quantity}>{totalItems}</span>
          </button>
        )}
        <Cart
          fetch={fetch}
          products={products}
          total={total}
          onCheckoutClicked={() => checkout(products)}
        />
        <a
          href="https://portal.fondy.eu/mportal/#/account/registration"
          className={s.wantBtn}
        >
          <Ink />
          <FormattedMessage
            id="cart.i_want"
            defaultMessage="I want FONDY checkout"
          />
        </a>
      </div>
    );
  }
}
CartContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  total: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
  checkout: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: getCartProducts(state),
  total: getTotal(state),
  totalItems: getCartTotal(state),
});

export default connect(mapStateToProps, { checkout })(
  withStyles(s)(CartContainer),
);
