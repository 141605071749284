import React from 'react';
import { compose } from 'react-apollo';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Home.css';
import ProductsContainer from './ProductsContainer';
import CartContainer from './CartContainer';

const messages = defineMessages({
  bannerTitle: {
    id: 'header.banner.title',
    defaultMessage: 'E',
    description: 'Title in page header',
  },
  bannerDesc: {
    id: 'header.banner.desc',
    defaultMessage: '-Shop',
    description: 'Description in header',
  },
});
class Home extends React.Component {
  render() {
    return (
      <div className={s.root}>
        <div className={s.banner}>
          <h1 className={s.bannerTitle}>
            <FormattedMessage {...messages.bannerTitle} />
            <span className={s.mini}>
              <FormattedMessage {...messages.bannerDesc} />
            </span>
          </h1>
        </div>
        <div className={s.container}>
          <ProductsContainer />
          <CartContainer fetch={this.props.fetch} />
        </div>
      </div>
    );
  }
}
Home.propTypes = {
  fetch: PropTypes.func.isRequired,
};

export default compose(withStyles(s))(Home);
