import shop from '../../data/cart/shop';
import * as types from '../../constants/ActionTypes';
import { API } from '../../constants';

const receiveProducts = products => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = () => dispatch => {
  dispatch(receiveProducts(shop.getProducts()));
};

const addToCartUnsafe = productId => ({
  type: types.ADD_TO_CART,
  productId,
});

const saveSession = (productId, fetch) => {
  fetch(API.SAVE_CART, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(productId),
  }).then(r => r.json());
};

export const addToCart = productId => (dispatch, getState, { fetch }) => {
  const element = document.getElementById('cartButton');
  if (getState().products.byId[productId].inventory > 0) {
    dispatch(addToCartUnsafe(productId));
    if (element) {
      element.classList.add('cart-pulse');
      setTimeout(() => {
        element.classList.remove('cart-pulse');
      }, 420);
    }
    const { cart } = getState();
    saveSession({ cart }, fetch);
  }
};
export const removeFromCart = productId => (dispatch, getState, { fetch }) => {
  dispatch({
    type: types.REMOVE_FROM_CART,
    productId,
  });
  dispatch({
    type: types.RESTORE_PRODUCTS,
    productId,
  });
  const { cart } = getState();
  saveSession({ cart }, fetch);
};

export const checkout = products => (dispatch, getState) => {
  const { cart } = getState();

  dispatch({
    type: types.CHECKOUT_REQUEST,
  });
  shop.buyProducts(products, () => {
    dispatch({
      type: types.CHECKOUT_SUCCESS,
      cart,
    });
    saveSession({ cart }, fetch);
    // dispatch({ type: types.CHECKOUT_FAILURE, cart });
  });
};
