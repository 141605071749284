exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(//fonts.googleapis.com/css?family=Roboto&subset=cyrillic);", ""]);

// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Roboto', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px; /* Extra small screen / phone */\n  --screen-sm-min: 768px; /* Small screen / tablet */\n  --screen-md-min: 992px; /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  /*\n     * Colors\n     * ======================================================================== */\n  --main-color: #4195c3;\n  --secondary-color: #3d3d3d;\n  --light-main: #c6d9f0;\n}\n\n._1ybjg {\n  display: block;\n  margin-top: 20px;\n}\n\n._1zOhM {\n  display: -ms-flexbox;\n  display: flex;\n}\n\n@media screen and (max-width: 768px) {\n  ._1zOhM {\n    display: block;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "_1ybjg",
	"listContainer": "_1zOhM"
};