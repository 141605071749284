exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(//fonts.googleapis.com/css?family=Roboto&subset=cyrillic);", ""]);

// module
exports.push([module.id, ":root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Roboto', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px; /* Extra small screen / phone */\n  --screen-sm-min: 768px; /* Small screen / tablet */\n  --screen-md-min: 992px; /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  /*\n     * Colors\n     * ======================================================================== */\n  --main-color: #4195c3;\n  --secondary-color: #3d3d3d;\n  --light-main: #c6d9f0;\n}\n\n._1avl7 {\n  padding: 0 20px 20px 20px;\n  margin-top: 30px;\n}\n\n._3YPN- {\n  margin: 0 auto;\n  padding: 0 0 20px;\n  max-width: 1000px;\n  max-width: var(--max-content-width);\n}\n\n._1J_oc {\n  text-align: center;\n  padding-top: 20px;\n}\n\n._1G50O {\n  margin: 0;\n  padding: 10px;\n  font-weight: normal;\n  font-size: 4em;\n  line-height: 1em;\n  position: relative;\n  border-bottom: 2px solid #3d3d3d;\n  border-radius: 13px;\n  border-top: 2px solid #3d3d3d;\n  display: inline-block;\n  width: 110px;\n  text-align: left;\n}\n\n@media screen and (max-width: 430px) {\n  ._1avl7 {\n    padding-left: 5px;\n    padding-right: 5px;\n  }\n}\n\n._2xwzn {\n  color: #4195c3;\n  color: var(--main-color);\n  font-size: 26px;\n  top: -5px;\n  position: absolute;\n}\n", ""]);

// exports
exports.locals = {
	"root": "_1avl7",
	"container": "_3YPN-",
	"banner": "_1J_oc",
	"bannerTitle": "_1G50O",
	"mini": "_2xwzn"
};