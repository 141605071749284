import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Ink from 'react-ink';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Product from './Product';
import s from './ProductItem.css';

const ProductItem = ({ product, onAddToCartClicked, bordered }) => (
  <div className={s.product}>
    <div className={s.productInner}>
      <Product
        bordered={bordered}
        title={product.title}
        price={product.price}
        quantity={product.inventory}
        images={product.images}
      />
      <button
        className={s.checkoutBtn}
        onClick={onAddToCartClicked}
        disabled={product.inventory > 0 ? '' : 'disabled'}
      >
        <Ink />
        {product.inventory > 0 ? (
          <FormattedMessage
            id="product_item.add_to_cart"
            defaultMessage="Add to cart"
          />
        ) : (
          <FormattedMessage
            id="product_item.sold_out"
            defaultMessage="Sold Out"
          />
        )}
      </button>
    </div>
  </div>
);

ProductItem.propTypes = {
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    inventory: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
  }).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
  bordered: PropTypes.bool,
};
ProductItem.defaultProps = {
  bordered: false,
};
export default withStyles(s)(ProductItem);
