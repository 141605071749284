/* eslint-env es6 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addToCart } from '../../actions/cart';
import { getVisibleProducts } from '../../reducers/cart/products';
import ProductItem from '../../components/Product/ProductItem';
import ProductsList from '../../components/ProductList/ProductsList';

const ProductsContainer = ({ allProducts, addToCart }) => (
  <ProductsList>
    {allProducts.map(product => (
      <ProductItem
        bordered
        key={product.id}
        product={product}
        onAddToCartClicked={() => addToCart(product.id)}
      />
    ))}
  </ProductsList>
);

ProductsContainer.propTypes = {
  allProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      inventory: PropTypes.number.isRequired,
    }),
  ).isRequired,
  addToCart: PropTypes.func.isRequired,
};

const mapState = state => ({
  allProducts: getVisibleProducts(state),
});

export default connect(mapState, { addToCart })(ProductsContainer);
